// TODO: MOVE THESE TO HELPERS
import React from 'react';
import { banks, currencies, genres, languages, locales, parentalAdvisories, rightsClaims } from 'constants/index';

export const prettifyDuration = duration => Math.floor(duration / 60).toString().padStart(2, 0) + ':' + (duration % 60).toString().padStart(2, 0);
export const dateToTimestamp = date => Math.floor(+date / 1000);
export const timestampToDate = timestamp => new Date(timestamp * 1000);
export const optionsFilter = (target, all, targetValue = 'value', allValue = 'value') => all && all.filter((el) => !(target || []).find(t => el[allValue] === t[targetValue])); //https://stackoverflow.com/questions/53603040/filter-array-of-objects-by-another-array-of-objects
export const toOption = (array, value = 'id', label = 'name') => (typeof array?.map === 'function') &&
  (array.length ? array.filter(e => e).map((object) => ({
    value: object[value],
    label: object[label],
  })) : null);

// TODO: Use this finder helpers for all.
const finder = (needle, haystack) => haystack.find(({ id }) => id === needle)
export const findLanguage = (language) => languages.find(({ id }) => id === language);
export const findGenre = (genre) => genres.find(({ id }) => id === genre);
export const findLocale = (locale) => locales.find(({ id }) => id === locale);
export const findBank = (bank) => banks.find(({ id }) => id === bank);
export const findCurrency = (currency) => currencies.find(({ id }) => id === currency);
export const findParentalAdvisory = (parentalAdvisory) => parentalAdvisories.find(({ id }) => id === parentalAdvisory);
export const findRightsClaim = rightsClaim => finder(rightsClaim, rightsClaims);

export const displayArtist = (artists, featurings) => {
  if(!artists) {
    return null;
  }

  const formatNames = (names) => names.map(({ name }) => name).join(' & ');

  const mainArtists = formatNames(artists);
  const featuredArtists = featurings?.length ? ` feat. ${formatNames(featurings)}` : '';

  return <>{mainArtists}{featuredArtists}</>;
};

export const getStoredAccount = () => JSON.parse(localStorage.getItem('currentUser'));
export const getToken = () => getStoredAccount()?.token;
export const getRole = () => getStoredAccount()?.role;
export const isAdmin = () => getRole() === 'admin';
export const isUser = () => getRole() === 'user';

export async function getFileDimensions (file) {
  return await new Promise((resolve, reject) => {
    const url = URL.createObjectURL(file);
    const image = new Image();
    image.onload = (e) => {
      const { height, width } = e.target;
      return resolve({ height, width });
    };
    image.src = url;
  });
}

