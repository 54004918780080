import React from 'react';
import { connect } from 'react-redux';

import { Route, Redirect, withRouter} from 'react-router-dom';
import { getCurrentUser } from '../../helpers/Utils';
import { isAuthGuardActive } from '../../constants/defaultValues';

const ProtectedRoute = ({ component: Component, account, roles = undefined, ...rest }) => {
  const setComponent = (props) => {
        if (isAuthGuardActive) {
            if (account) {
                // TODO: refactor roles, incldues and else; early return would be enough instead of returning Comp in 2 different places
                if (roles) {
                    if (roles.includes(account.role)) {
                        return <Component {...props} />;
                    } else {
                        return <Redirect
                            to={{
                                pathname: '/unauthorized',
                                state: { from: props.location },
                            }} />
                    }
                } else {
                    return <Component {...props} />;
                }
            } else {
                return <Redirect
                    to={{
                        pathname: '/user',
                        state: { from: props.location },
                    }} />
            }
        } else {
            return <Component {...props} />;
        }
    }

    return (
        <Route
            {...rest}
            render={setComponent}
        />
    );
}
const UserRole = {
    Admin: 'admin',
    Label: 'label',
    User: 'user',
}

export { UserRole };

const mapStateToProps = ({ authUser: { currentUser: account } }) => {
  return { account };
};
const mapActionToProps = {};

export default withRouter(
  connect(mapStateToProps, mapActionToProps)(ProtectedRoute)
);

