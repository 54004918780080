const localDomains = ['localhost', '127.0.0.1'];
export const isLocalHost = localDomains.includes(window.location.hostname);

export const baseURL = isLocalHost ? 'http://api.dijitaldagitim' : 'https://api.dijitaldagitim.pro';

export const genres = [
  {
    'id': 'ALTERNATIVE',
    'name': 'Alternative',
  },
  {
    'id': 'AUDIOBOOKS',
    'name': 'Audiobooks',
  },
  {
    'id': 'BLUES',
    'name': 'Blues',
  },
  {
    'id': 'CHILDRENS_MUSIC',
    'name': 'Children\'s Music',
  },
  {
    'id': 'CLASSICAL',
    'name': 'Classical',
  },
  {
    'id': 'COMEDY',
    'name': 'Comedy',
  },
  {
    'id': 'COUNTRY',
    'name': 'Country',
  },
  {
    'id': 'DANCE',
    'name': 'Dance',
  },
  {
    'id': 'ELECTRONIC',
    'name': 'Electronic',
  },
  {
    'id': 'FOLK',
    'name': 'Folk',
  },
  {
    'id': 'HIP_HOP_RAP',
    'name': 'Hip Hop/Rap',
  },
  {
    'id': 'HOLIDAY',
    'name': 'Holiday',
  },
  {
    'id': 'INSPIRATIONAL',
    'name': 'Inspirational',
  },
  {
    'id': 'JAZZ',
    'name': 'Jazz',
  },
  {
    'id': 'LATIN',
    'name': 'Latin',
  },
  {
    'id': 'NEW_AGE',
    'name': 'New Age',
  },
  {
    'id': 'OPERA',
    'name': 'Opera',
  },
  {
    'id': 'POP',
    'name': 'Pop',
  },
  {
    'id': 'R_B_SOUL',
    'name': 'R&B/Soul',
  },
  {
    'id': 'REGGAE',
    'name': 'Reggae',
  },
  {
    'id': 'ROCK',
    'name': 'Rock',
  },
  {
    'id': 'SPOKEN_WORD',
    'name': 'Spoken Word',
  },
  {
    'id': 'SOUNDTRACK',
    'name': 'Soundtrack',
  },
  {
    'id': 'VOCAL',
    'name': 'Vocal',
  },
  {
    'id': 'WORLD',
    'name': 'World',
  },
];

export const languages = [
  {
    'id': 'TR',
    'name': 'Turkish',
  },
  {
    'id': 'EN',
    'name': 'English',
  },
  {
    'id': 'AZ',
    'name': 'Azerbaijani',
  },
  {
    'id': 'AR',
    'name': 'Arabic',
  },
  {
    'id': 'DE',
    'name': 'German',
  },
  {
    'id': 'KU',
    'name': 'Kurdish',
  },
  {
    'id': 'AA',
    'name': 'Afar',
  },
  {
    'id': 'AB',
    'name': 'Abkhazian',
  },
  {
    'id': 'AE',
    'name': 'Avestan',
  },
  {
    'id': 'AF',
    'name': 'Afrikaans',
  },
  {
    'id': 'AK',
    'name': 'Akan',
  },
  {
    'id': 'AM',
    'name': 'Amharic',
  },
  {
    'id': 'AN',
    'name': 'Aragonese',
  },
  {
    'id': 'AS',
    'name': 'Assamese',
  },
  {
    'id': 'AV',
    'name': 'Avaric',
  },
  {
    'id': 'AY',
    'name': 'Aymara',
  },
  {
    'id': 'BA',
    'name': 'Bashkir',
  },
  {
    'id': 'BE',
    'name': 'Belarusian',
  },
  {
    'id': 'BG',
    'name': 'Bulgarian',
  },
  {
    'id': 'BHO',
    'name': 'Bhojpuri',
  },
  {
    'id': 'BI',
    'name': 'Bislama',
  },
  {
    'id': 'BM',
    'name': 'Bambara',
  },
  {
    'id': 'BN',
    'name': 'Bengali',
  },
  {
    'id': 'BO',
    'name': 'Tibetan',
  },
  {
    'id': 'BR',
    'name': 'Breton',
  },
  {
    'id': 'BS',
    'name': 'Bosnian',
  },
  {
    'id': 'CA',
    'name': 'Catalan',
  },
  {
    'id': 'CE',
    'name': 'Chechen',
  },
  {
    'id': 'CH',
    'name': 'Chamorro',
  },
  {
    'id': 'CMN_HANS',
    'name': 'Chinese (Simplified)',
  },
  {
    'id': 'CMN_HANT',
    'name': 'Chinese (Traditional)',
  },
  {
    'id': 'CO',
    'name': 'Corsican',
  },
  {
    'id': 'CPE',
    'name': 'Creole, English based',
  },
  {
    'id': 'CPF',
    'name': 'Creole, French based',
  },
  {
    'id': 'CPP',
    'name': 'Creole, Portuguese based',
  },
  {
    'id': 'CR',
    'name': 'Cree',
  },
  {
    'id': 'CS',
    'name': 'Czech',
  },
  {
    'id': 'CU',
    'name': 'Church Slavic',
  },
  {
    'id': 'CV',
    'name': 'Chuvash',
  },
  {
    'id': 'CY',
    'name': 'Welsh',
  },
  {
    'id': 'DA',
    'name': 'Danish',
  },
  {
    'id': 'DV',
    'name': 'Divehi',
  },
  {
    'id': 'DZ',
    'name': 'Dzongkha',
  },
  {
    'id': 'EE',
    'name': 'Ewe',
  },
  {
    'id': 'EL',
    'name': 'Greek',
  },
  {
    'id': 'EO',
    'name': 'Esperanto',
  },
  {
    'id': 'ES',
    'name': 'Spanish',
  },
  {
    'id': 'ES_419',
    'name': 'Spanish (Latin America)',
  },
  {
    'id': 'ET',
    'name': 'Estonian',
  },
  {
    'id': 'EU',
    'name': 'Basque',
  },
  {
    'id': 'FA',
    'name': 'Persian',
  },
  {
    'id': 'FF',
    'name': 'Fulah',
  },
  {
    'id': 'FI',
    'name': 'Finnish',
  },
  {
    'id': 'FJ',
    'name': 'Fijian',
  },
  {
    'id': 'FO',
    'name': 'Faroese',
  },
  {
    'id': 'FR',
    'name': 'French',
  },
  {
    'id': 'FY',
    'name': 'Western Frisian',
  },
  {
    'id': 'GA',
    'name': 'Irish',
  },
  {
    'id': 'GD',
    'name': 'Gaelic',
  },
  {
    'id': 'GL',
    'name': 'Galician',
  },
  {
    'id': 'GN',
    'name': 'Guarani',
  },
  {
    'id': 'GU',
    'name': 'Gujarati',
  },
  {
    'id': 'GV',
    'name': 'Manx',
  },
  {
    'id': 'HA',
    'name': 'Hausa',
  },
  {
    'id': 'HAT',
    'name': 'Haitian Creole',
  },
  {
    'id': 'HE',
    'name': 'Hebrew',
  },
  {
    'id': 'HI',
    'name': 'Hindi',
  },
  {
    'id': 'HO',
    'name': 'Hiri Motu',
  },
  {
    'id': 'HR',
    'name': 'Croatian',
  },
  {
    'id': 'HT',
    'name': 'Haitian',
  },
  {
    'id': 'HU',
    'name': 'Hungarian',
  },
  {
    'id': 'HY',
    'name': 'Armenian',
  },
  {
    'id': 'HZ',
    'name': 'Herero',
  },
  {
    'id': 'IA',
    'name': 'Interlingua',
  },
  {
    'id': 'ID',
    'name': 'Indonesian',
  },
  {
    'id': 'IE',
    'name': 'Interlingue',
  },
  {
    'id': 'IG',
    'name': 'Igbo',
  },
  {
    'id': 'II',
    'name': 'Sichuan Yi',
  },
  {
    'id': 'IK',
    'name': 'Inupiaq',
  },
  {
    'id': 'IO',
    'name': 'Ido',
  },
  {
    'id': 'IS',
    'name': 'Icelandic',
  },
  {
    'id': 'IT',
    'name': 'Italian',
  },
  {
    'id': 'IU',
    'name': 'Inuktitut',
  },
  {
    'id': 'JA',
    'name': 'Japanese',
  },
  {
    'id': 'JA_KANA',
    'name': 'Japanese Katakana',
  },
  {
    'id': 'JA_LATN',
    'name': 'Japanese Roman',
  },
  {
    'id': 'JA_HANI',
    'name': 'Japanese Kanji',
  },
  {
    'id': 'JA_HRKT',
    'name': 'Japanese Furigana',
  },
  {
    'id': 'JV',
    'name': 'Javanese',
  },
  {
    'id': 'KA',
    'name': 'Georgian',
  },
  {
    'id': 'KG',
    'name': 'Kongo',
  },
  {
    'id': 'KI',
    'name': 'Kikuyu',
  },
  {
    'id': 'KJ',
    'name': 'Kuanyama',
  },
  {
    'id': 'KK',
    'name': 'Kazakh',
  },
  {
    'id': 'KL',
    'name': 'Kalaallisut',
  },
  {
    'id': 'KM',
    'name': 'Central Khmer',
  },
  {
    'id': 'KN',
    'name': 'Kannada',
  },
  {
    'id': 'KO',
    'name': 'Korean',
  },
  {
    'id': 'KR',
    'name': 'Kanuri',
  },
  {
    'id': 'KS',
    'name': 'Kashmiri',
  },
  {
    'id': 'KV',
    'name': 'Komi',
  },
  {
    'id': 'KW',
    'name': 'Cornish',
  },
  {
    'id': 'KY',
    'name': 'Kirghiz',
  },
  {
    'id': 'LA',
    'name': 'Latin',
  },
  {
    'id': 'LB',
    'name': 'Luxembourgish',
  },
  {
    'id': 'LG',
    'name': 'Luganda',
  },
  {
    'id': 'LI',
    'name': 'Limburgan',
  },
  {
    'id': 'LN',
    'name': 'Lingala',
  },
  {
    'id': 'LO',
    'name': 'Lao',
  },
  {
    'id': 'LT',
    'name': 'Lithuanian',
  },
  {
    'id': 'LU',
    'name': 'Luba-Katanga',
  },
  {
    'id': 'LV',
    'name': 'Latvian',
  },
  {
    'id': 'MG',
    'name': 'Malagasy',
  },
  {
    'id': 'MAG',
    'name': 'Magahi',
  },
  {
    'id': 'MAI',
    'name': 'Maithili',
  },
  {
    'id': 'MH',
    'name': 'Marshallese',
  },
  {
    'id': 'MI',
    'name': 'Maori',
  },
  {
    'id': 'MK',
    'name': 'Macedonian',
  },
  {
    'id': 'ML',
    'name': 'Malayalam',
  },
  {
    'id': 'MN',
    'name': 'Mongolian',
  },
  {
    'id': 'MR',
    'name': 'Marathi',
  },
  {
    'id': 'MS',
    'name': 'Malay',
  },
  {
    'id': 'MT',
    'name': 'Maltese',
  },
  {
    'id': 'MY',
    'name': 'Burmese',
  },
  {
    'id': 'NA',
    'name': 'Nauru',
  },
  {
    'id': 'NB',
    'name': 'Norwegian Bokmål',
  },
  {
    'id': 'ND',
    'name': 'North Ndebele',
  },
  {
    'id': 'NE',
    'name': 'Nepali',
  },
  {
    'id': 'NG',
    'name': 'Ndonga',
  },
  {
    'id': 'NL',
    'name': 'Dutch',
  },
  {
    'id': 'NN',
    'name': 'Norwegian Nynorsk',
  },
  {
    'id': 'NO',
    'name': 'Norwegian',
  },
  {
    'id': 'NR',
    'name': 'South Ndebele',
  },
  {
    'id': 'NV',
    'name': 'Navajo',
  },
  {
    'id': 'NY',
    'name': 'Chichewa',
  },
  {
    'id': 'OC',
    'name': 'Occitan',
  },
  {
    'id': 'OJ',
    'name': 'Ojibwa',
  },
  {
    'id': 'OM',
    'name': 'Oromo',
  },
  {
    'id': 'OR',
    'name': 'Oriya',
  },
  {
    'id': 'OS',
    'name': 'Ossetian',
  },
  {
    'id': 'PA',
    'name': 'Punjabi',
  },
  {
    'id': 'PI',
    'name': 'Pali',
  },
  {
    'id': 'PL',
    'name': 'Polish',
  },
  {
    'id': 'PS',
    'name': 'Pushto',
  },
  {
    'id': 'PT',
    'name': 'Portuguese',
  },
  {
    'id': 'QU',
    'name': 'Quechua',
  },
  {
    'id': 'RM',
    'name': 'Romansh',
  },
  {
    'id': 'RN',
    'name': 'Rundi',
  },
  {
    'id': 'RO',
    'name': 'Romanian',
  },
  {
    'id': 'RU',
    'name': 'Russian',
  },
  {
    'id': 'RW',
    'name': 'Kinyarwanda',
  },
  {
    'id': 'SA',
    'name': 'Sanskrit',
  },
  {
    'id': 'SC',
    'name': 'Sardinian',
  },
  {
    'id': 'SD',
    'name': 'Sindhi',
  },
  {
    'id': 'SE',
    'name': 'Northern Sami',
  },
  {
    'id': 'SG',
    'name': 'Sango',
  },
  {
    'id': 'SI',
    'name': 'Sinhala',
  },
  {
    'id': 'SK',
    'name': 'Slovak',
  },
  {
    'id': 'SL',
    'name': 'Slovene',
  },
  {
    'id': 'SM',
    'name': 'Samoan',
  },
  {
    'id': 'SN',
    'name': 'Shona',
  },
  {
    'id': 'SO',
    'name': 'Somali',
  },
  {
    'id': 'SQ',
    'name': 'Albanian',
  },
  {
    'id': 'SR',
    'name': 'Serbian',
  },
  {
    'id': 'SS',
    'name': 'Swati',
  },
  {
    'id': 'ST',
    'name': 'Sotho, Southern',
  },
  {
    'id': 'SU',
    'name': 'Sundanese',
  },
  {
    'id': 'SV',
    'name': 'Swedish',
  },
  {
    'id': 'SW',
    'name': 'Swahili',
  },
  {
    'id': 'TA',
    'name': 'Tamil',
  },
  {
    'id': 'TE',
    'name': 'Telugu',
  },
  {
    'id': 'TG',
    'name': 'Tajik',
  },
  {
    'id': 'TH',
    'name': 'Thai',
  },
  {
    'id': 'TI',
    'name': 'Tigrinya',
  },
  {
    'id': 'TK',
    'name': 'Turkmen',
  },
  {
    'id': 'TL',
    'name': 'Tagalog',
  },
  {
    'id': 'TN',
    'name': 'Tswana',
  },
  {
    'id': 'TO',
    'name': 'Tonga (Tonga Islands)',
  },
  {
    'id': 'TS',
    'name': 'Tsonga',
  },
  {
    'id': 'TT',
    'name': 'Tatar',
  },
  {
    'id': 'TW',
    'name': 'Twi',
  },
  {
    'id': 'TY',
    'name': 'Tahitian',
  },
  {
    'id': 'UG',
    'name': 'Uighur',
  },
  {
    'id': 'UK',
    'name': 'Ukrainian',
  },
  {
    'id': 'UR',
    'name': 'Urdu',
  },
  {
    'id': 'UZ',
    'name': 'Uzbek',
  },
  {
    'id': 'VE',
    'name': 'Venda',
  },
  {
    'id': 'VI',
    'name': 'Vietnamese',
  },
  {
    'id': 'VO',
    'name': 'Volapük',
  },
  {
    'id': 'WA',
    'name': 'Walloon',
  },
  {
    'id': 'WO',
    'name': 'Wolof',
  },
  {
    'id': 'XH',
    'name': 'Xhosa',
  },
  {
    'id': 'YI',
    'name': 'Yiddish',
  },
  {
    'id': 'YUE_HANT',
    'name': 'Cantonese',
  },
  {
    'id': 'YO',
    'name': 'Yoruba',
  },
  {
    'id': 'ZA',
    'name': 'Zhuang',
  },
  {
    'id': 'ZH',
    'name': 'Chinese',
  },
  {
    'id': 'ZU',
    'name': 'Zulu',
  },
];

export const locales = [
  { 'id': 'ZXX', 'name': 'Instrumental' },
  { 'id': 'TR', 'name': 'Turkish' },
  { 'id': 'EN', 'name': 'English' },
  { 'id': 'AZ', 'name': 'Azerbaijani' },
  { 'id': 'AR', 'name': 'Arabic' },
  { 'id': 'DE', 'name': 'German' },
  { 'id': 'KU', 'name': 'Kurdish' },
  { 'id': 'AA', 'name': 'Afar' },
  { 'id': 'AB', 'name': 'Abkhazian' },
  { 'id': 'AE', 'name': 'Avestan' },
  { 'id': 'AF', 'name': 'Afrikaans' },
  { 'id': 'AK', 'name': 'Akan' },
  { 'id': 'AM', 'name': 'Amharic' },
  { 'id': 'AN', 'name': 'Aragonese' },
  { 'id': 'AS', 'name': 'Assamese' },
  { 'id': 'AV', 'name': 'Avaric' },
  { 'id': 'AY', 'name': 'Aymara' },
  { 'id': 'BA', 'name': 'Bashkir' },
  { 'id': 'BE', 'name': 'Belarusian' },
  { 'id': 'BG', 'name': 'Bulgarian' },
  { 'id': 'BHO', 'name': 'Bhojpuri' },
  { 'id': 'BI', 'name': 'Bislama' },
  { 'id': 'BM', 'name': 'Bambara' },
  { 'id': 'BN', 'name': 'Bengali' },
  { 'id': 'BO', 'name': 'Tibetan' },
  { 'id': 'BR', 'name': 'Breton' },
  { 'id': 'BS', 'name': 'Bosnian' },
  { 'id': 'CA', 'name': 'Catalan' },
  { 'id': 'CE', 'name': 'Chechen' },
  { 'id': 'CH', 'name': 'Chamorro' },
  { 'id': 'CO', 'name': 'Corsican' },
  { 'id': 'CPE', 'name': 'Creole, English based' },
  { 'id': 'CPF', 'name': 'Creole, French based' },
  { 'id': 'CPP', 'name': 'Creole, Portuguese based' },
  { 'id': 'CR', 'name': 'Cree' },
  { 'id': 'CS', 'name': 'Czech' },
  { 'id': 'CU', 'name': 'Church Slavic' },
  { 'id': 'CV', 'name': 'Chuvash' },
  { 'id': 'CY', 'name': 'Welsh' },
  { 'id': 'DA', 'name': 'Danish' },
  { 'id': 'DV', 'name': 'Divehi' },
  { 'id': 'DZ', 'name': 'Dzongkha' },
  { 'id': 'EE', 'name': 'Ewe' },
  { 'id': 'EL', 'name': 'Greek' },
  { 'id': 'EO', 'name': 'Esperanto' },
  { 'id': 'ES', 'name': 'Spanish' },
  { 'id': 'ET', 'name': 'Estonian' },
  { 'id': 'EU', 'name': 'Basque' },
  { 'id': 'FA', 'name': 'Persian' },
  { 'id': 'FF', 'name': 'Fulah' },
  { 'id': 'FI', 'name': 'Finnish' },
  { 'id': 'FJ', 'name': 'Fijian' },
  { 'id': 'FO', 'name': 'Faroese' },
  { 'id': 'FR', 'name': 'French' },
  { 'id': 'FY', 'name': 'Western Frisian' },
  { 'id': 'GA', 'name': 'Irish' },
  { 'id': 'GD', 'name': 'Gaelic' },
  { 'id': 'GL', 'name': 'Galician' },
  { 'id': 'GN', 'name': 'Guarani' },
  { 'id': 'GU', 'name': 'Gujarati' },
  { 'id': 'GV', 'name': 'Manx' },
  { 'id': 'HA', 'name': 'Hausa' },
  { 'id': 'HAT', 'name': 'Haitian Creole' },
  { 'id': 'HE', 'name': 'Hebrew' },
  { 'id': 'HI', 'name': 'Hindi' },
  { 'id': 'HO', 'name': 'Hiri Motu' },
  { 'id': 'HR', 'name': 'Croatian' },
  { 'id': 'HT', 'name': 'Haitian' },
  { 'id': 'HU', 'name': 'Hungarian' },
  { 'id': 'HY', 'name': 'Armenian' },
  { 'id': 'HZ', 'name': 'Herero' },
  { 'id': 'IA', 'name': 'Interlingua' },
  { 'id': 'ID', 'name': 'Indonesian' },
  { 'id': 'IE', 'name': 'Interlingue' },
  { 'id': 'IG', 'name': 'Igbo' },
  { 'id': 'II', 'name': 'Sichuan Yi' },
  { 'id': 'IK', 'name': 'Inupiaq' },
  { 'id': 'IO', 'name': 'Ido' },
  { 'id': 'IS', 'name': 'Icelandic' },
  { 'id': 'IT', 'name': 'Italian' },
  { 'id': 'IU', 'name': 'Inuktitut' },
  { 'id': 'JA', 'name': 'Japanese' },
  { 'id': 'JV', 'name': 'Javanese' },
  { 'id': 'KA', 'name': 'Georgian' },
  { 'id': 'KG', 'name': 'Kongo' },
  { 'id': 'KI', 'name': 'Kikuyu' },
  { 'id': 'KJ', 'name': 'Kuanyama' },
  { 'id': 'KK', 'name': 'Kazakh' },
  { 'id': 'KL', 'name': 'Kalaallisut' },
  { 'id': 'KM', 'name': 'Central Khmer' },
  { 'id': 'KN', 'name': 'Kannada' },
  { 'id': 'KO', 'name': 'Korean' },
  { 'id': 'KR', 'name': 'Kanuri' },
  { 'id': 'KS', 'name': 'Kashmiri' },
  { 'id': 'KV', 'name': 'Komi' },
  { 'id': 'KW', 'name': 'Cornish' },
  { 'id': 'KY', 'name': 'Kirghiz' },
  { 'id': 'LA', 'name': 'Latin' },
  { 'id': 'LB', 'name': 'Luxembourgish' },
  { 'id': 'LG', 'name': 'Luganda' },
  { 'id': 'LI', 'name': 'Limburgan' },
  { 'id': 'LN', 'name': 'Lingala' },
  { 'id': 'LO', 'name': 'Lao' },
  { 'id': 'LT', 'name': 'Lithuanian' },
  { 'id': 'LU', 'name': 'Luba-Katanga' },
  { 'id': 'LV', 'name': 'Latvian' },
  { 'id': 'MAG', 'name': 'Magahi' },
  { 'id': 'MAI', 'name': 'Maithili' },
  { 'id': 'MG', 'name': 'Malagasy' },
  { 'id': 'MH', 'name': 'Marshallese' },
  { 'id': 'MI', 'name': 'Maori' },
  { 'id': 'MK', 'name': 'Macedonian' },
  { 'id': 'ML', 'name': 'Malayalam' },
  { 'id': 'MN', 'name': 'Mongolian' },
  { 'id': 'MR', 'name': 'Marathi' },
  { 'id': 'MS', 'name': 'Malay' },
  { 'id': 'MT', 'name': 'Maltese' },
  { 'id': 'MY', 'name': 'Burmese' },
  { 'id': 'NA', 'name': 'Nauru' },
  { 'id': 'NB', 'name': 'Norwegian Bokmål' },
  { 'id': 'ND', 'name': 'North Ndebele' },
  { 'id': 'NE', 'name': 'Nepali' },
  { 'id': 'NG', 'name': 'Ndonga' },
  { 'id': 'NL', 'name': 'Dutch' },
  { 'id': 'NN', 'name': 'Norwegian Nynorsk' },
  { 'id': 'NO', 'name': 'Norwegian' },
  { 'id': 'NR', 'name': 'South Ndebele' },
  { 'id': 'NV', 'name': 'Navajo' },
  { 'id': 'NY', 'name': 'Chichewa' },
  { 'id': 'OC', 'name': 'Occitan' },
  { 'id': 'OJ', 'name': 'Ojibwa' },
  { 'id': 'OM', 'name': 'Oromo' },
  { 'id': 'OR', 'name': 'Oriya' },
  { 'id': 'OS', 'name': 'Ossetian' },
  { 'id': 'PA', 'name': 'Punjabi' },
  { 'id': 'PI', 'name': 'Pali' },
  { 'id': 'PL', 'name': 'Polish' },
  { 'id': 'PS', 'name': 'Pushto' },
  { 'id': 'PT', 'name': 'Portuguese' },
  { 'id': 'QU', 'name': 'Quechua' },
  { 'id': 'RM', 'name': 'Romansh' },
  { 'id': 'RN', 'name': 'Rundi' },
  { 'id': 'RO', 'name': 'Romanian' },
  { 'id': 'RU', 'name': 'Russian' },
  { 'id': 'RW', 'name': 'Kinyarwanda' },
  { 'id': 'SA', 'name': 'Sanskrit' },
  { 'id': 'SC', 'name': 'Sardinian' },
  { 'id': 'SD', 'name': 'Sindhi' },
  { 'id': 'SE', 'name': 'Northern Sami' },
  { 'id': 'SG', 'name': 'Sango' },
  { 'id': 'SI', 'name': 'Sinhala' },
  { 'id': 'SK', 'name': 'Slovak' },
  { 'id': 'SL', 'name': 'Slovene' },
  { 'id': 'SM', 'name': 'Samoan' },
  { 'id': 'SN', 'name': 'Shona' },
  { 'id': 'SO', 'name': 'Somali' },
  { 'id': 'SQ', 'name': 'Albanian' },
  { 'id': 'SR', 'name': 'Serbian' },
  { 'id': 'SS', 'name': 'Swati' },
  { 'id': 'ST', 'name': 'Sotho, Southern' },
  { 'id': 'SU', 'name': 'Sundanese' },
  { 'id': 'SV', 'name': 'Swedish' },
  { 'id': 'SW', 'name': 'Swahili' },
  { 'id': 'TA', 'name': 'Tamil' },
  { 'id': 'TE', 'name': 'Telugu' },
  { 'id': 'TG', 'name': 'Tajik' },
  { 'id': 'TH', 'name': 'Thai' },
  { 'id': 'TI', 'name': 'Tigrinya' },
  { 'id': 'TK', 'name': 'Turkmen' },
  { 'id': 'TL', 'name': 'Tagalog' },
  { 'id': 'TN', 'name': 'Tswana' },
  { 'id': 'TO', 'name': 'Tonga (Tonga Islands)' },
  { 'id': 'TS', 'name': 'Tsonga' },
  { 'id': 'TT', 'name': 'Tatar' },
  { 'id': 'TW', 'name': 'Twi' },
  { 'id': 'TY', 'name': 'Tahitian' },
  { 'id': 'UG', 'name': 'Uighur' },
  { 'id': 'UK', 'name': 'Ukrainian' },
  { 'id': 'UR', 'name': 'Urdu' },
  { 'id': 'UZ', 'name': 'Uzbek' },
  { 'id': 'VE', 'name': 'Venda' },
  { 'id': 'VI', 'name': 'Vietnamese' },
  { 'id': 'VO', 'name': 'Volapük' },
  { 'id': 'WA', 'name': 'Walloon' },
  { 'id': 'WO', 'name': 'Wolof' },
  { 'id': 'XH', 'name': 'Xhosa' },
  { 'id': 'YI', 'name': 'Yiddish' },
  { 'id': 'YUE', 'name': 'Cantonese' },
  { 'id': 'YO', 'name': 'Yoruba' },
  { 'id': 'ZA', 'name': 'Zhuang' },
  { 'id': 'ZH', 'name': 'Chinese' },
  { 'id': 'ZU', 'name': 'Zulu' },
];

export const contributorRoles = [
  {
    'id': 'ARRANGER',
    'name': 'Arranger',
  },
  {
    'id': 'CHOIR',
    'name': 'Choir',
  },
  {
    'id': 'COMPOSER',
    'name': 'Composer',
  },
  {
    'id': 'CONDUCTOR',
    'name': 'Conductor',
  },
  {
    'id': 'CONTRIBUTING_ARTIST',
    'name': 'Contributing Artist',
  },
  {
    'id': 'ENGINEER',
    'name': 'Engineer',
  },
  {
    'id': 'ENSEMBLE',
    'name': 'Ensemble',
  },
  {
    'id': 'FEATURING',
    'name': 'Featuring',
  },
  {
    'id': 'LYRICIST',
    'name': 'Lyricist',
  },
  {
    'id': 'MIXER',
    'name': 'Mixer',
  },
  {
    'id': 'ORCHESTRA',
    'name': 'Orchestra',
  },
  {
    'id': 'PERFORMER',
    'name': 'Performer',
  },
  {
    'id': 'PRODUCER',
    'name': 'Producer',
  },
  {
    'id': 'REMIXER',
    'name': 'Remixer',
  },
  {
    'id': 'SOLOIST',
    'name': 'Soloist',
  },
  {
    'id': 'WRITER',
    'name': 'Writer',
  },
  {
    'id': 'ACTOR',
    'name': 'Actor',
  },
  {
    'id': 'VIDEO_DIRECTOR',
    'name': 'Video Director',
  },
  {
    'id': 'VIDEO_PRODUCER',
    'name': 'Video Producer',
  },
];

// TODO: Translate
export const rightsClaims = [
  { id: 'NONE', name: 'Talep Etme' },
  { id: 'MONETIZE', name: 'Talep Et' },
];

export const parentalAdvisories = [
  { id: 'YES', name: 'Evet' },
  { id: 'NO', name: 'Hayır' },
  { id: 'CLEAN', name: 'Sansürlü' },
];

// export const demoStores =   [
//   {
//     'name': 'Apple Music (Demo) 2',
//     'id': 1723776097,
//     'artist_identifier': true,
//     'is_iip_dds': false,
//   },
//   {
//     name:	"Spotify (Demo) 2",
//     id:	1723792805,
//     is_iip_dds:	false
//   }
// ];

// export const liveStores =   [
//   {
//     'name': 'Spotify',
//     'id': 746109,
//     'is_iip_dds': true,
//     'artist_identifier': {
//       prefix: 'spotify:artist:',
//     },
//     'hidden': false,
//   },
//   {
//     'name': 'Apple Music',
//     'id': 1330598,
//     'is_iip_dds': true,
//     'artist_identifier': true,
//     'hidden': false,
//   },
//   {
//     'name': 'Shazam',
//     'id': 4266325,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'YouTube Music',
//     'id': 49262307,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'Instagram Music', // Facebook Audio Library
//     'id': 1499657856,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'TikTok',
//     'id': 1809226414,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'Deezer',
//     'id': 2100357,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'Fizy',
//     'id': 1988507361,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'Tidal', // TIDAL
//     'id': 3440259,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'Amazon Music',
//     'id': 99268,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'SoundCloud',
//     'id': 35299696,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'Yandex Music',
//     'id': 1209287754,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'Anghami',
//     'id': 20799134,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'United Media Agency (UMA)',
//     'id': 1210987244,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'TouchTunes / PlayNetwork',
//     'id': 1130831671,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'TIM Music',
//     'id': 1207204780,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'Tencent',
//     'id': 1461025062,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'Slacker',
//     'id': 16149060,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'Simfy Africa',
//     'id': 78395129,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'Resso',
//     'id': 1963181516,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'Qobuz',
//     'id': 9940949,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'Pretzel Rocks',
//     'id': 2406442214,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'Pandora',
//     'id': 7851192,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'Nuuday A/S',
//     'id': 464139,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'Neurotic Media',
//     'id': 62873543,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'NetEase Cloud Music',
//     'id': 1382854531,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'Napster',
//     'id': 103731,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'MonkingMe',
//     'id': 3287973537,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'LINE Music',
//     'id': 1232212955,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'Kuack Media',
//     'id': 1226212715,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'KkBox',
//     'id': 121452605,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'Joox',
//     'id': 1517454273,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'iMusica',
//     'id': 103725,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'iHeartRadio',
//     'id': 3441649,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'Facebook Fingerprinting',
//     'id': 1415672002,
//     'is_iip_dds': true,
//     'hidden': true, // Mandatory, cannot remove.
//   },
//   {
//     'name': 'Bmat',
//     'id': 1158892521,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': 'AWA',
//     'id': 847103579,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   {
//     'name': '7Digital',
//     'id': 247916,
//     'is_iip_dds': true,
//     'hidden': false,
//   },
//   // {
//   //   'name': 'YouTube Content ID',
//   //   'id': 1048705,
//   //   'is_iip_dds': true,
//   // },
//   // {
//   //   'name': 'YouTube Music',
//   //   'id': 13285026,
//   //   'is_iip_dds': true,
//   // },
// ];

// export const stores = isLocalHost ? demoStores : liveStores;

export const productStates = [
  {
    id: 'PENDING',
    name: 'draft',
    color: 'warning',
  },
  {
    id: 'PUBLISHED',
    name: 'approved',
    color: 'warning',
  },
  {
    id: 'DELIVERED',
    name: 'delivered',
    color: 'success',
  },
  {
    id: 'DELETED',
    name: 'deleted',
    color: 'danger',
  },
];

export const productStatus = [
  {
    id: 'APPROVED',
    name: 'approved',
    color: 'success',
  },
  {
    id: 'WAITING',
    name: 'waiting',
    color: 'warning',
  },
  {
    id: 'REJECTED',
    name: 'rejected',
    color: 'danger',
  },
  // {
  //   id: 'NEW',
  //   name: 'new',
  //   color: 'info'
  // }
];

// TODO: Make it array of objects(id,name,color)
export const onAir = {
  true: 'true',
  false: 'false',
};

export const currencies = [
  {
    'id': 'EUR',
    'name': 'currency.eur',
  },
  {
    'id': 'TRY',
    'name': 'currency.try',
  }
];

export const banks = [
  {
    'id': 1,
    'name': 'Türkiye İş Bankası',
  },
  {
    'id': 2,
    'name': 'Türkiye Halk Bankası',
  },
  {
    'id': 3,
    'name': 'Türkiye Garanti Bankası',
  },
  {
    'id': 4,
    'name': 'T.C. Ziraat Bankası',
  },
  {
    'id': 5,
    'name': 'Yapı Kredi Bankası',
  },
  {
    'id': 6,
    'name': 'Vakıfbank',
  },
  {
    'id': 7,
    'name': 'Akbank',
  },
  {
    'id': 8,
    'name': 'QNB Finansbank',
  },
  {
    'id': 9,
    'name': 'ING Bank',
  },
  {
    'id': 10,
    'name': 'Denizbank',
  },
  {
    'id': 11,
    'name': 'HSBC',
  },
  {
    'id': 12,
    'name': 'Türk Ekonomi Bankası (TEB)',
  },
  {
    'id': 13,
    'name': 'The Barclays Bank A/C',
  },
  {
    'id': 14,
    'name': 'Wise',
  },
  {
    'id': 15,
    'name': 'Lloyds Bank plc',
  },
];

// TODO: locale
export const assetFields = {
  'composers': 'Besteci',
  'locale': 'Söz Dili',
  'language': 'Dil',
  'genre': 'Tür',
  'title': 'Eser Adı',
  'artists': 'Birincil Sanatçı',
  'lyricists': 'Söz Yazarı',
  'parentalAdvisory': 'Argo Sözler',
  'state': 'Durum'
}

export const assetErrors = {
  'empty': 'Boş',
  'is_banned': 'Yasaklı',
}

// TODO: Translate
export const accountStatus = {
  ACTIVE: { text: 'Aktif', color: 'success' },
  DELETED: { text: 'Silinmiş', color: 'danger' },
  DRAFT: { text: 'Taslak', color: 'muted' },
  PENDING: { text: 'Ödeme Bekleniyor', color: 'muted' },
  SUSPENDED: { text: 'İptal', color: 'danger' },
};


// TODO: Translate
export const subscriptionStatus = {
  ACTIVE: { text: 'Aktif', color: 'success' },
  CANCELED: { text: 'İptal', color: 'light' },
  EXPIRED: { text: 'Süresi dolmuş', color: 'danger' },
};

export const plans = {
  STANDARD: 'Standard',
  PREMIUM: 'Premium',
  PRO: 'Pro',
  LEGACY: 'Legacy',
  BRONZE: 'Bronze',
  BRONZE_PLUS: 'Bronze+',
  SILVER: 'Silver',
  GOLD: 'Gold',
};

export const currencySymbols = {
  TRY: '₺',
  EUR: '€',
}

