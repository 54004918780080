import { baseURL } from 'constants/index';
import download from 'downloadjs';
import { getStoredAccount, getToken } from 'helpers';

function objectToUrlParams (obj = {}) {
  if (!Object.keys(obj)?.length) return '';
  return '?' + new URLSearchParams(obj).toString();
}

// export const logout = () => localStorage.removeItem('currentUser');

// const sendRequest = (path, ...args) => fetch(`${baseURL}${path}`, ...args).then(r => console.log(r.json()) || r.json());

// TODO: merge getJSON, putJSON, postJSON, deleteJSON or made them with closure
//  use fetchMiddleware to that closure
//  look into https://swr.vercel.app/docs/error-handling for swr compatibility
// idea: https://www.tjvantoll.com/2015/09/13/fetch-and-errors/
// derived from(source): https://jasonwatmore.com/post/2021/10/09/fetch-error-handling-for-failed-http-responses-and-network-errors
export const handleFetch = async (response) => {
  const isJson = response.headers.get('content-type')?.includes('application/json');
  const responseJson = isJson ? await response.json() : null;

  // check for error response
  if (!response.ok) {
    // get error message from body or default to response status
    const error = (responseJson && responseJson.error) || response.status;
    return Promise.reject({...error, status: response.status});
  }

  return responseJson.data;
}

// TODO: now you can use putJSON since r.ok() && r.json() is removed from common methods
export const putPasswordAccount = (data = {}) =>
  fetch(`${baseURL}/accounts/password`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${getToken() || ''}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });


const pullFormFiles = (files) => {
  // love the query selector
  // cache files.length
  const fl = files.length;
  let i = 0;

  const arr = [];

  while ( i < fl ) {
    arr.push(files.item(i++));
  }
  return arr;
};

export const JSONtoFormData = (data) => {
  let formData = new FormData();
  for ( let key in data ) {
    if (typeof data[key] === 'object' && data[key] instanceof FileList) {
      formData.append(key, data[key][0]);
    } else if (data[key]) {
      formData.append(key, data[key]);
    }
  }
  return formData;
};

const submitForm = (path, data, method = 'POST') =>
  fetch(`${baseURL}${path}`, {
    method: method,
    headers: {
      Authorization: `Bearer ${getToken() || ''}`,
      // 'Content-Type': 'multipart/form-data',
    },
    body: JSONtoFormData(data),
  });

// const createRequest = (method) => (path, data = {}) => {
//   return fetch(`${baseURL}${path}`, {
//     method,
//     headers: {
//       Authorization: `Bearer ${getToken() || ''}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(data),
//   });
// };
//
// const postJSON = createRequest('POST');
// const putJSON = createRequest('PUT');

const postJSON = (path, data = {}) =>
  fetch(`${baseURL}${path}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getToken() || ''}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

const putJSON = (path, data = {}) =>
  fetch(`${baseURL}${path}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${getToken() || ''}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

const deleteJSON = (path, data = {}) =>
  fetch(`${baseURL}${path}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${getToken() || ''}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

const getJSON = (path, params = {}) => {
  const queryString = objectToUrlParams(params);

  return fetch(`${baseURL}${path}${queryString}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      // 'Content-Type': 'application/json',
    },
    // body: JSON.stringify(data),
  })
};

// const downloadFile = (path, name = null) => {
//   return fetch(`${baseURL}${path}`, {
//     method: 'GET',
//     headers: {
//       Authorization: `Bearer ${getStoredAccount().token}`,
//     },
//   }).then(res => res.blob())
//     .then(blob => download(blob, name));
// };

// TODO: Fix method and how params work
const downloadFile = (path, name = null, params = null) => {
  return fetch(`${baseURL}${path}`, {
    method: params?.method || 'GET',
    headers: {
      Authorization: `Bearer ${getStoredAccount().token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params?.data),
  })
    .then(res => {
      if (!res.ok) {
        // Handle the failed response here, for example, throw an error or log a message.
        throw new Error(`Failed to download file. Status: ${res.status} ${res.statusText}`);
      }
      return res.blob();
    })
    .then(blob => download(blob, name))
    .catch(error => {
      // Handle any errors that occurred during the fetch or processing
      console.error('Error:', error.message);
      // You might want to throw the error again or handle it differently based on your use case.
      throw error;
    });
};

export const login = (data) => postJSON('/login', data).then(handleFetch);

export const register = (data) => postJSON('/register', data).then(handleFetch);
export const keepAlive = () => getJSON('/keep-alive').then(handleFetch);
export const forgotPassword = (data) => postJSON('/forgot-password', data).then(handleFetch);
export const subscribe = (data) => postJSON('/subscribe', data).then(handleFetch);
export const getUnfinishedSubscription = () => getJSON('/subscribe').then(handleFetch);
export const getPaymentsConfig = () => getJSON(`/payments/config`).then((r) => r.ok && r.json()).then(({ data }) => data);

// export const getProducts = (id) => getJSON(`/products${id ? '/' + id : ''}`).then(({ data }) => data);
// TODO: Review useSWR usage.
// TODO: Review how to get arguments, object or separate args
//  Use seperate args, since useSWR passes arguments like that, and passing all args in a single object would brake it.
export const getProduct = id => getJSON(`/products/${id}`).then((r) => r.ok && r.json()).then(({ data }) => data);
export const getProducts = () => getJSON(`/products`).then((r) => r.ok && r.json()).then(({ data }) => data);
export const createArtistProduct = (product, data) => postJSON(`/products/${product}/artists`, data).then((r) => r.ok && r.json()).then(({ data }) => data);
export const postArtistProduct = (product, artist, featuring) => postJSON(`/products/${product}/artists/${artist}${featuring ? '?featuring' : ''}`).then((r) => r.ok && r.json()).then(({ data }) => data);
export const putMetaProduct = (product, data) => putJSON(`/products/${product}/meta`, data).then((r) => r.ok && r.json()).then(({ data }) => data);
export const deleteArtistProduct = (product, artist) => deleteJSON(`/products/${product}/artists/${artist}`).then((r) => r.ok && r.json()).then(({ data }) => data);
export const putStoresProduct = (product, data) => putJSON(`/products/${product}/stores`, data).then(handleFetch);
export const postStoreProduct = (product, store) => postJSON(`/products/${product}/store/${store}`).then((r) => r.ok && r.json()).then(({ data }) => data);
export const deleteStoreProduct = (product, store) => deleteJSON(`/products/${product}/store/${store}`).then((r) => r.ok && r.json()).then(({ data }) => data);
export const downloadImageProduct = (id, name) => downloadFile(`/products/${id}/image`, `${name}`);
export const requestApprovalProduct = product => postJSON(`/products/${product}/approval`).then((r) => r.ok && r.json()).then(({ data }) => data);
export const reviewApprovalProduct = (product, data) => putJSON(`/products/${product}/approval`, data).then((r) => r.ok && r.json()).then(({ data }) => data);

export const takedownRequestProduct = (product, data) => postJSON(`/products/${product}/takedown`, data).then((r) => r.ok && r.json()).then(({ data }) => data);
export const takedownProduct = (product, data) => putJSON(`/products/${product}/takedown`, data).then((r) => r.ok && r.json()).then(({ data }) => data);

export const deleteRequestProduct = (product, data) => postJSON(`/products/${product}/delete`, data).then((r) => r.ok && r.json()).then(({ data }) => data);
export const deleteProduct = (product, data) => putJSON(`/products/${product}/delete`, data).then((r) => r.ok && r.json()).then(({ data }) => data);

export const detachAsset = (id, asset) => deleteJSON(`/products/${id}/assets/${asset}`).then((r) => r.ok && r.json()).then(({ data }) => data);


export const createProduct = data => submitForm(`/products`, data).then((r) => r.ok && r.json()).then(({ data }) => data);
export const imageProduct = ({ id, data }) => submitForm(`/products/${id}/image`, data).then((r) => r.ok && r.json()).then(({ data }) => data);
export const createAssetProduct = ({ id, data }) => submitForm(`/products/${id}/assets`, data).then((r) => r.ok && r.json()).then(({ data }) => data);
export const attachAssetProduct = ({ product, asset }) => postJSON(`/products/${product}/assets/${asset}`).then(handleFetch);

export const putMetaAsset = (asset, data) => putJSON(`/assets/${asset}/meta`, data).then((r) => r.ok && r.json()).then(({ data }) => data);

export const createArtistAsset = (asset, data) => postJSON(`/assets/${asset}/artists`, data).then((r) => r.ok && r.json()).then(({ data }) => data);
export const postArtistAsset = (asset, artist, featuring) => postJSON(`/assets/${asset}/artists/${artist}${featuring ? '?featuring' : ''}`).then((r) => r.ok && r.json()).then(({ data }) => data);
export const deleteArtistAsset = (asset, artist) => deleteJSON(`/assets/${asset}/artists/${artist}`).then((r) => r.ok && r.json()).then(({ data }) => data);

export const createContributorAsset = (asset, data) => postJSON(`/assets/${asset}/contributor`, data).then((r) => r.ok && r.json()).then(({ data }) => data);
export const postContributorAsset = (asset, person, role) => postJSON(`/assets/${asset}/contributor/${person}?role=${role}`).then((r) => r.ok && r.json()).then(({ data }) => data);
export const deleteContributorAsset = (asset, contributor, role) => deleteJSON(`/assets/${asset}/contributor/${contributor}?role=${role}`).then((r) => r.ok && r.json()).then(({ data }) => data);
export const downloadAudioAsset = (id, name) => downloadFile(`/assets/${id}/audio`, `${name}`);
export const deleteAsset = id => deleteJSON(`/assets/${id}`).then((r) => r.ok && r.json()).then(({ data }) => data);

// export const getArtist = id => getJSON(`/artists/${id}`).then((r) => r.ok && r.json()).then(({ data }) => data);
export const updateArtist = (id, data) => putJSON(`/artists/${id}`, data).then(handleFetch);
export const getArtists = (key, params) => getJSON(`/artists`, params).then(handleFetch);

export const getPerson = id => getJSON(`/persons/${id}`).then((r) => r.ok && r.json()).then(({ data }) => data);
export const getPersons = (key, params) => getJSON(`/persons`, params).then(handleFetch);

export const getLabels = (key, params) => getJSON(`/labels`, params).then(handleFetch);
export const createLabel = data => postJSON(`/labels`, data).then(handleFetch);

export const getAsset = id => getJSON(`/assets/${id}`).then((r) => r.ok && r.json()).then(({ data }) => data);
export const getAssets = (key, params) => getJSON(`/assets`, params).then(handleFetch);

export const getAccounts = (key, params) => getJSON(`/accounts/list`, params).then(handleFetch);
export const getAccount = () => getJSON(`/accounts`).then((r) => r.ok && r.json()).then(({ data }) => data);
export const putAccount = (data) => putJSON(`/accounts`, data).then((r) => r.ok && r.json()).then(({ data }) => data);
export const getAccountCapabilities = (key, params) => getJSON(`/accounts/capabilities`, params).then(handleFetch);
export const getAccountStores = () => getJSON(`/accounts/stores`).then(handleFetch);
export const suspendAccount = (data) => putJSON(`/accounts/suspend`, data).then(handleFetch);

export const getSubscription = (key, params) => getJSON(`/accounts/subscription`, params).then(handleFetch);
export const getPaymentMethods = (key, params) => getJSON(`/accounts/payments/methods`, params).then(handleFetch);
export const addPaymentMethod = data => postJSON(`/accounts/payments/methods`, data).then(handleFetch);
export const setDefaultPaymentMethod = data => putJSON(`/accounts/payments/methods`, data).then(handleFetch);

export const downloadAccountAgreement = (id, name) => downloadFile(`/accounts/${id}/agreement`, `${name}`);
export const generateAgreement = (name, params) => downloadFile(`/accounts/agreement`, name, params);

export const getAgreement = (name) => getJSON(`/agreements/${name}`).then(handleFetch);
export const signAgreement = (id, data) => postJSON(`/agreements/${id}/sign`, data).then(handleFetch);

export const getPeriods = () => getJSON('/accounting/periods').then((r) => r.ok && r.json()).then(({ data }) => data);
export const getPeriodsOld = () => getJSON('/accounting/periodsOld').then((r) => r.ok && r.json()).then(({ data }) => data);
export const getFilters = () => getJSON('/accounting/filters').then((r) => r.ok && r.json()).then(({ data }) => data);
export const getRevenues = (key, id, data) => postJSON(`/accounting/revenues/${id}`, data).then((r) => r.ok && r.json()).then(({ data }) => data);
export const getTransactions = () => getJSON(`/accounting/transactions`).then((r) => r.ok && r.json()).then(({ data }) => data);
export const requestWithdraw = (data) => postJSON(`/accounting/transactions`, data).then(handleFetch);
export const smsTransactions = (data) => postJSON(`/accounting/transactions/sms`, data).then(handleFetch);
export const downloadReceipt = (id, name) => downloadFile(`/accounting/transactions/${id}/receipt`, `${name}`);

export const getSMSBalance = () => getJSON(`/system/sms/balance`).then(handleFetch)
